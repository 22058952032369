body {
  user-select: none;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div{
  user-select: none;
}
*{
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.am-popover {
  height: 300px !important;
  overflow: scroll;
  left: 40px !important;
  right: 40px !important;
}

.am-popover-inner-wrapper{
  background-color: #f8f8f8 !important;
  box-shadow: 2px 2px 5px #ab9b9b !important;
}
.am-popover-item-container{
  height: auto !important;
  padding: 10px 0px !important;
}
